import React from 'react';
import ReactMarkdown from "react-markdown";
import '../App.css';
import Markdown from 'react-markdown';

const IdealCard = ({ title, description }) => {

    return(
            <a href="#" class="block max-w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                
            </h5>
            <p class="font-normal text-gray-700 dark:text-gray-400">
                <pre><Markdown>{description["foreword"]}</Markdown></pre>
            </p>
            <p class="font-normal text-gray-700 dark:text-gray-400">
                <pre><Markdown>{description["introduction"]}</Markdown></pre>
            </p>
            <p class="font-normal text-gray-700 dark:text-gray-400">
                <pre><Markdown>{description["chapter"]}</Markdown></pre>
            </p>
            <p class="font-normal text-gray-700 dark:text-gray-400">
                <pre><Markdown>{description["faqs"]}</Markdown></pre>
            </p>
            <p class="font-normal text-gray-700 dark:text-gray-400">
                <pre><Markdown>{description["saqs"]}</Markdown></pre>
            </p>
            <p class="font-normal text-gray-700 dark:text-gray-400">
                <pre><Markdown>{description["epilogue"]}</Markdown></pre>
            </p>
            </a>
)
}
export default IdealCard;
