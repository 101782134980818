import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import './dist/tailwind.css';
const { REACT_APP_AZURE_CLIENT_ID, REACT_APP_AZURE_AUTHORITY } = process.env;

const msalReact = {
  auth: {
    clientId: REACT_APP_AZURE_CLIENT_ID,
    authority: REACT_APP_AZURE_AUTHORITY,
    redirectUri: window.location.origin,
  },
};

const msalInstance = new PublicClientApplication(msalReact);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
