import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ currentStep }) => {
  const [hoveredStep, setHoveredStep] = useState(null);

  const getClassName = (step) => {
    if (step === currentStep) {
      return 'inline-flex items-center p-2 text-gray-900 rounded-lg dark:text-white bg-[#FFFF00] min-w-[150px] relative';
    }
    if (step > currentStep) {
      return 'flex items-center p-2 text-gray-400 rounded-lg dark:text-gray-500 cursor-not-allowed relative';
    }
    return 'flex items-center p-2 text-blue-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group relative';
  };  

  const menuItems = [
    { 
      step: 1, 
      path: '/', 
      label: 'Start Here',
      description: 'Welcome To Your Marketing Magic Journey!'
    },
    { 
      step: 2, 
      path: '/baps', 
      label: 'Problems You Can Solve',
      description: 'Discover the biggest problems your ideal clients face and how you can solve them.'
    },
    { 
      step: 3, 
      path: '/customer-avatars', 
      label: 'Ideal Clients To Target',
      description: 'Identify and understand the customers who are most likely to buy from you.'
    },
    { 
      step: 4, 
      path: '/lead-magnets', 
      label: 'Lead Magnet Ideas for you',
      description: 'Discover creative ways to add values and get prospects to know, like, and trust you.'
    },
    { 
      step: 5, 
      path: '/ideal-book-recommendation', 
      label: 'Proposal of Your Authority Book',
      description: 'Based on your input earlier we recommend this book for you in order to build authority positioning in your niche.'
    },
    { 
      step: 6, 
      path: '/ideal-book', 
      label: 'Your Authority Book',
      description: 'Your finshed book is here. Time to create unshakeable branding and dominate your industry.'
    },
    { 
      step: 7, 
      path: '/sales-letter', 
      label: 'Copywriting To Sell Your Book',
      description: 'Your sales letter'
    },
    { 
      step: 8, 
      path: '/audio-book', 
      label: 'Your Authority Audiobook',
      description: 'Your audio book'
    }
  ];

  const TooltipContent = ({ description }) => (
    <div className="fixed transform -translate-y left-64 ml-4 px-4 py-2 bg-gray-200 text-black text-sm rounded-md w-64 shadow-lg z-50">
      <div className="absolute left-0 top-1/2 -translate-x-1 -translate-y-1/2 w-2 h-2 bg-gray-200 rotate-45"></div>
      {description}
    </div>
  );
  

  return (
    <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
      <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
        <a href="https://rediminds.com/" className="flex items-center ps-2.5 mb-5">
          <img src="AI_logo.png" className="w-full h-auto" alt="Logo" />
        </a>
        
        <ul className="space-y-2 font-medium">
          {menuItems.map(({ step, path, label, description }) => (
            <li key={step} className="relative"
                onMouseEnter={() => setHoveredStep(step)}
                onMouseLeave={() => setHoveredStep(null)}>
              {step <= currentStep ? (
                <Link 
                  to={path} 
                  className={getClassName(step)}
                >
                  <span className="flex-1 ms-3 whitespace-nowrap">
                    <b>{String(step).padStart(2, '0')}</b> {label}
                  </span>
                  {hoveredStep === step && <TooltipContent description={description} />}
                </Link>
              ) : (
                <div className={getClassName(step)}>
                  <span className="flex-1 ms-3 whitespace-nowrap">
                    <b>{String(step).padStart(2, '0')}</b> {label}
                  </span>
                  {hoveredStep === step && <TooltipContent description={description} />}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;