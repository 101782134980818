import React from 'react';
import '../App.css';
import ReactMarkdown from 'react-markdown';

const LEAD_MAGNETS_SEPARATOR = "0000000000000000";

// Function to get the image src from a split string
const getImageSrcFromString = (inputString) => {
  const splitStringArray = inputString.split(LEAD_MAGNETS_SEPARATOR);
  const base64Image = splitStringArray[1].slice(11,-2);
  const imgSrc = `data:image/png;base64,${base64Image}`;
  console.log(imgSrc) // Fixed template literal syntax
  return imgSrc;
};

const LeadMagnetsCard = ({ description }) => {
  return (
    <div className="flex flex-col w-full my-6 bg-white shadow-sm border border-slate-200 rounded-lg">
      {/* Limit to 5 cards */}
      {description.slice(0, 5).map((desc, index) => {
        const imageSrc = getImageSrcFromString(desc);
        const textContent = desc.split(LEAD_MAGNETS_SEPARATOR)[0];

        return (
          <div
            key={index}
            className="relative flex flex-row w-full my-6 bg-white shadow-sm border border-slate-200 rounded-lg max-w-full overflow-hidden"
          >
            <div className="relative flex-shrink-0 w-1/3 h-full itmes-center">
              <img
                src={imageSrc}
                alt={`card-image-${index}`}
                className="h-full w-full object-cover pl-5 pt-9 "
              />
            </div>
            <div className="p-6 flex-1 flex items-center">
              <p className="text-gray-900 leading-normal break-words">
                {/* Render markdown text with no overflow */}
                <ReactMarkdown>{textContent}</ReactMarkdown>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};


export default LeadMagnetsCard;
