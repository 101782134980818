import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../App.css';
import { SidebarWithContentSeparator } from './sideBar';
import { Textarea } from '@material-tailwind/react';
import Sidebar from './sideBar';

function Home() {
	const [name, setName] = useState('');
	const [businessDescription, setBusinessDescription] = useState('');
	const [websiteUrl, setWebsiteUrl] = useState('');
	const [ultimateBusinessVision, setUltimateBusinessVision] = useState('');
	const [specificBusinessProblems, setSpecificBusinessProblems] = useState('');
	const [customerResults, setCustomerResults] = useState('');
	const [productsOrServices, setProductsOrServices] = useState('');
	const [idealClientProfiles, setIdealClientProfiles] = useState('');
	const [leadMagnetsUsed, setLeadMagnetsUsed] = useState('');
	const [mainContentThemes, setMainContentThemes] = useState('');
	const [error, setError] = useState(null);
	const navigate = useNavigate(); 
	const currentStep = 1;

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const data = {
				name,
				business_description: businessDescription,
				website_url: websiteUrl,
				ultimate_business_vision: ultimateBusinessVision,
				specific_business_problems: specificBusinessProblems,
				customer_results: customerResults,
				products_or_services: productsOrServices,
				ideal_client_profiles: idealClientProfiles,
				lead_magnets_used: leadMagnetsUsed,
				main_content_themes: mainContentThemes,
			};
			const response = await fetch('https://glowing-invention-backend-755833753464.us-east1.run.app/api/home', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			const responseData = await response.json();
			const sessionId = responseData.session_id;
			localStorage.setItem('sessionId', sessionId);
			navigate('/baps'); // Correct usage of the navigate function
		} catch (error) {
			console.log(error);
			setError(error.error); // Update error state with error message
		}
	};


	return (
		<>

		<button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
		<span class="sr-only">Open sidebar</span>
		<svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
		</svg>
		</button>

		<div>
		<Sidebar currentStep={currentStep} />
		</div>

			<div class="p-4 sm:ml-64">
			<div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
			<div class="  mb-4">
			<div >
			<h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
			Welcome To Your <span className="bg-[#FFFF00]">Marketing Magic</span> Journey!
			</h1>
			<p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
			Let's discover how you can leverage your knowledge efficiently!
			</p>

			{error && (
				<div className="error-message">
				<p>{error}</p>
				</div>
			)}

			<form onSubmit={handleSubmit} class="max-w-xl mx-auto ">
			<div class="mb-5  ">
			<label for="user_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Name:</label>
			<input
			type="text"
			id="user_name"
			value={name}
			onChange={(e) => setName(e.target.value)}
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			required/>
			</div>
			<div class="mb-5">
			<label for="business_description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Describe your business in a few sentences. What do you offer, and who are your primary customers?</label>
			<textarea id="business_description" value={businessDescription}  rows="6" onChange={(e) => setBusinessDescription(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>

			</div>
			<div class="mb-5">
			<label for="website_url" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Website URL:</label>
			<textarea id="website_URL" value={websiteUrl} rows="1" onChange={(e) => setWebsiteUrl(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>

			</div>
			<div class="mb-5">
			<label for="ultimate_business_vision" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
			What is your ultimate business vision for the next year? What specific goals do you want to achieve?
			</label>
			<textarea id="ultimate_business_vision"
			value={ultimateBusinessVision}
			onChange={(e) => setUltimateBusinessVision(e.target.value)}  rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>  


			</div>

			<div class="mb-5">
			<label for="specific_business_problems" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">What specific problems do your clients typically face that your business helps solve?</label>
			<textarea id="specific_business_problems"
			value={specificBusinessProblems} rows="6"
			onChange={(e) => setSpecificBusinessProblems(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>  


			</div>



			<div class="mb-5">
			<label for="customer_results" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
			What kind of results do your customers typically experience after working with you?</label>
			<textarea id="customer_results"
			value={customerResults} rows="6"
			onChange={(e) => setCustomerResults(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>  


			</div>



			<div class="mb-5">
			<label for="products_or_services" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">What are the main products or services you offer, and what are their prices?
			</label>
			<textarea    
			id="products_or_services"
			value={productsOrServices}
			onChange={(e) => setProductsOrServices(e.target.value)} rows="6"
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			/>
			</div>

			<div class="mb-5">
			<label for="ideal_client_profiles" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">In your opinion/experience who are your ideal clients? Please provide detailed customer profiles if available.</label>
			<textarea    
			type="text"
			id="ideal_client_profiles"
			value={idealClientProfiles}
			rows="6"
			onChange={(e) => setIdealClientProfiles(e.target.value)}
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			/>
			</div>
			<div class="mb-5">
			<label for="lead_magnets_used" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">What lead magnets have you used or are considering to attract your ideal clients?</label>
			<textarea
			type="text"
			id="lead_magnets_used"
			value={leadMagnetsUsed}
			rows="6"
			onChange={(e) => setLeadMagnetsUsed(e.target.value)}
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			/>
			</div>
			<div class="mb-5">
			<label for="main_content_themes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Describe any book or major content pieces you have created or plan to create. What are the main themes or topics?</label>
			<textarea
			type="text"
			id="main_content_themes"
			value={mainContentThemes}
			rows="6"
			onChange={(e) => setMainContentThemes(e.target.value)}
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			/>
			</div>


			<div class="flex items-center justify-center w-full">
			<label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">Upload a document
			<div class="flex flex-col items-center justify-center pt-5 pb-6">
			<svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
			<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
			</svg>
			<p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
			<p class="text-xs text-gray-500 dark:text-gray-400">Any</p>
			</div>
			<input id="dropzone-file" type="file" class="hidden" />
			</label>


			</div> 
			<div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">A document that could help us better understand your world</div>




			<div className="flex justify-end w-full mt-6 mb-2">
			<button type="submit" onClick={handleSubmit} className="text-gray-900 bg-[#FFFF00] hover:bg-yellow-200 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full px-8 py-2.5 text-center">
			Submit Your Input and let the Magic get started!
			</button>
			</div>
			<div class="mt-1 text-sm text-gray-500 dark:text-gray-300 text-center">
			...and grab a coffee to enjoy the process as the hard work on your end is done!
			</div>


			</form>


			</div>


			</div>
			</div>
			</div>

			</>


		);

		}

		export default Home;


