import React, { useState } from 'react';
import NextButton from './NextButton';
import { useNavigate, Link } from 'react-router-dom';
import Sidebar from './sideBar';



// Then we'll create the AudioBook component
const AudioBook = () => {



	const history = useNavigate();
	const handleNext = () => {
		history("/sales-letter");
	};

	// We'll use a state to store the uploaded audio file
	const [audioFile, setAudioFile] = useState(null);

	// We'll use another state to track whether the request is being processed
	const [isUploading, setIsUploading] = useState(false);

	// We'll get the session_id from localStorage
	const sessionId = localStorage.getItem('sessionId');

	// We'll create a function to handle the file upload
	const handleUpload = (event) => {
		// Get the selected file
		const file = event.target.files[0];

		// Create a FormData object to send the file and session_id
		const jsonData = JSON.stringify({ session_id: sessionId });
		const formData = new FormData();
		formData.append('json_data', jsonData);
		formData.append('file', file);

		// Set isUploading to true
		setIsUploading(true);
		const delay = ms => new Promise(res => setTimeout(res, ms));
		delay(3000);

		// Send the request to localhost:5000/api/audio
		fetch('https://glowing-invention-backend-755833753464.us-east1.run.app/api/audio_book', {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.blob())
			.then((audioBlob) => {
				// Set the uploaded audio file
				setAudioFile(audioBlob);

				// Set isUploading to false
				setIsUploading(false);
			})
			.catch((error) => {
				// Handle the error
				console.error(error);

				// Set isUploading to false
				setIsUploading(false);
			});
	};

	const currentStep=8;

	// We'll render a file input to select the audio file
	return (

		<>

		<button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
		<span class="sr-only">Open sidebar</span>
		<svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
		</svg>
		</button>

		<div>
		<Sidebar currentStep={currentStep} />
		</div>

			<div class="p-4 sm:ml-64">
			<div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
			<div class="  mb-4">



			<div>

			<h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
			Your <mark className="bg-[#FFFF00]">Audio-Book</mark> 
			</h1>

			<div class="flex items-center justify-center w-full">
			<label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">Upload your audio sample
			<div class="flex flex-col items-center justify-center pt-5 pb-6">
			<svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
			<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
			</svg>
			<p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
			<p class="text-xs text-gray-500 dark:text-gray-400"> MP3</p>
			</div>
			<input id="dropzone-file" onChange={handleUpload} type="file" class="hidden" />
			</label>
			</div>

			{isUploading ? (
				<div>
				<div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>

				<p>Generating audiobook 🎙️</p>
				</div>
			) : (
				// If audioFile is not null, render the audio file
				audioFile ? (
					<div class="bg-gray-100 p-4 flex justify-center  h-screen">
					<audio controls>
					<source src={URL.createObjectURL(audioFile)} type="audio/mp3" />
					</audio>
					</div>
				) : (
					// If audioFile is null, display nothing
					null
				)
			)}
			{/* <NextButton handleNext={handleNext} /> */}






			</div>
			<NextButton handleNext={handleNext} />
			</div>
			</div>
			</div>
			</>
		);
		};

		export default AudioBook;
