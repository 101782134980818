import React from 'react';
import ReactMarkdown from "react-markdown";
import '../App.css';

const BapsCard = ({ title, description }) => {
  return (
    <div className="mobile-prest">
      <div
        style={{ minHeight: '300vh' }}
        className="relative flex flex-col px-4 pt-24 pb-12 font-sans text-gray-700 bg-blue-200 sm:px-6 lg:px-8"
      >
        <div style={{ textAlign: 'center' }} className="flex-1 space-y-8">
          <div
            style={{ top: 'calc(1rem * 1)' }}
            className="sticky w-full max-w-xl px-8 py-12 mx-auto space-y-4 bg-white border rounded-lg shadow-lg"
          >
            <h2 className="space-y-1 text-2xl font-bold leading-none text-gray-900">
              <span className="block text-sm text-blue-700">Big Problem 1</span>
            </h2>
            <pre><ReactMarkdown>{description[0]}</ReactMarkdown></pre>
          </div>

          <div
            style={{ top: 'calc(1rem * 2)' }}
            className="sticky w-full max-w-xl px-8 py-12 mx-auto space-y-4 bg-white border rounded-lg shadow-lg"
          >
            <h2 className="space-y-1 text-2xl font-bold leading-none text-gray-900">
              <span className="block text-sm text-red-700">Big Problem 2</span>
            </h2>
            <pre><ReactMarkdown>{description[1]}</ReactMarkdown></pre>
          </div>

          <div
            style={{ top: 'calc(1rem * 3)' }}
            className="sticky w-full max-w-xl px-8 py-12 mx-auto space-y-4 bg-white border rounded-lg shadow-lg"
          >
            <h2 className="space-y-1 text-2xl font-bold leading-none text-gray-900">
              <span className="block text-sm text-yellow-700">Big Problem 3</span>
            </h2>
            <pre><ReactMarkdown>{description[2]}</ReactMarkdown></pre>
          </div>

          <div
            style={{ top: 'calc(1rem * 4)' }}
            className="sticky w-full max-w-xl px-8 py-12 mx-auto space-y-4 bg-white border rounded-lg shadow-lg"
          >
            <h2 className="space-y-1 text-2xl font-bold leading-none text-gray-900">
              <span className="block text-sm text-purple-700">Big Problem 4</span>
            </h2>
            <pre><ReactMarkdown>{description[3]}</ReactMarkdown></pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BapsCard;
