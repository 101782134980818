import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Card from './card1';
import NextButton from './NextButton';
import LoadingWithQuotes from './animation';
import Sidebar from './sideBar';


const delay = ms => new Promise(res => setTimeout(res, ms));

function Ideal_Book_Recommendation() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const history = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			const sessionId = localStorage.getItem('sessionId');
			try {
				while (true) {
					delay(3000);
					const response = await fetch(`https://glowing-invention-backend-755833753464.us-east1.run.app/api/ideal_book_recommendation`, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ session_id: sessionId }),
					});

					if (response.status !== 200) {
						console.error('Unexpected status code:', response.status);
						continue; // Continue the loop if the status is not 200
					}

					const result = await response.json();
					setData(result);
					setLoading(false);
					break; // Exit the loop if the status is 200 and data is fetched successfully
				}
			} catch (error) {
				console.error('Error:', error);
				setLoading(false);
			}
		};

		fetchData();
	}, [location]);

	const handleNext = () => {
		history('/ideal-book');
	};

	if (loading) {
		return (
			<LoadingWithQuotes/>
		);
	}

	const currentStep=5;

	return (
		<>

		<button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
		<span class="sr-only">Open sidebar</span>
		<svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
		</svg>
		</button>

		<div>
		<Sidebar currentStep={currentStep} />
		</div>

			<div class="p-4 sm:ml-64">
			<div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
			<div class="  mb-4">




			<div>
			<h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
			Your Authority Book Blueprint <mark className="bg-[#FFFF00]">Proposal</mark> </h1>
			<p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
			Based on your input earlier we recommend this book for you in order to build authority positioning in your niche.
			</p>

			{data && (
				<div className="flex justify-center mt-10">
				<Card 
				title="" 
				description={data["ideal_book_recommendation"]} 
				/>
				</div>
			)}

			<NextButton handleNext={handleNext} />

			</div>

			</div>
			</div>
			</div>
			</>
		);
		}

		export default Ideal_Book_Recommendation;

