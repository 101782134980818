import React, { useState, useEffect } from 'react';

const LoadingWithQuotes = () => {
  // List of inspiring quotes
  const quotes = [
    "Follow your instincts. That's where True Wisdom Manifests itself -Oprah Winfrey",
    "The gap between a Yes and a No is where the opportunity to make Life Changing Money is. -Alex Hormozi",
    "Those who can Imagine Anything, create the IMPOSSIBLE. -Alex Turing",
    "Everything you do Is an Investment and a Decision. -Jay Abraham",
  ];
  
  // List of GIF URLs
  const gifs = [
    "https://giphy.com/embed/VdoNp9BUcnXRowPaeW",
    "https://giphy.com/embed/D81ZtVnn5Orio",
    "https://giphy.com/embed/l2SqdrcLUxoMSfc9W",
    "https://giphy.com/embed/d1E12OY7DjFkEBSU",
  ];

  // State to keep track of the current quote and GIF index
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [currentGifIndex, setCurrentGifIndex] = useState(0);

  // useEffect to change the quote every 4 seconds
  useEffect(() => {
    const quoteIntervalId = setInterval(() => {
      setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 4000);

    // Clear the interval on component unmount
    return () => clearInterval(quoteIntervalId);
  }, [quotes.length]);

  // useEffect to change the GIF every 5 seconds
  useEffect(() => {
    const gifIntervalId = setInterval(() => {
      setCurrentGifIndex((prevIndex) => (prevIndex + 1) % gifs.length);
    }, 6000);

    // Clear the interval on component unmount
    return () => clearInterval(gifIntervalId);
  }, [gifs.length]);

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="relative flex justify-center items-center mb-5">
        {/* <div className="absolute animate-spin rounded-full h-64 w-64 border-t-4 border-b-4 border-blue-500"></div> */}
        <iframe
          src={gifs[currentGifIndex]}
          width="480" height="480"   ></iframe>
          </div>
      
      {/* Display the current quote */}
      <div className="mt-1 text-sm text-gray-500 dark:text-gray-300 text-center">
        {/* {quotes[currentQuoteIndex]} */}
        Loading results
      </div>
    </div>
  );
};

export default LoadingWithQuotes;
