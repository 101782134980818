import React from 'react';
import ReactMarkdown from 'react-markdown';
import '../App.css';

const LEAD_MAGNETS_SEPARATOR = "0000000000000000"; // Define the separator

const getImageSrcFromString = (inputString) => {
    const splitStringArray = inputString.split(LEAD_MAGNETS_SEPARATOR);
    const base64Image = splitStringArray[1]?.slice(11, -2); // Safely access the second index
    const imgSrc = base64Image ? `data:image/png;base64,${base64Image}` : ''; // Create the image source
    return imgSrc;
};

const Card = ({ title, description }) => {
    // Split the description using the separator
    const splitDescription = description.split(LEAD_MAGNETS_SEPARATOR);
    const imageSrc = getImageSrcFromString(description); // Get the image source

    return (
        <a href="#" className="block max-w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {title}
            </h5>
            <div className="relative">
                {imageSrc && ( // Conditionally render the image if the src is available
                    <img
                        src={imageSrc}
                        alt={`card-image`}
                        className="float-left w-64 h-64 object-contain mr-4" // Use float-left to allow text to wrap around
                    />
                )}
                <div className="font-normal text-gray-700 dark:text-gray-400">
                    {/* Render the first index of the split description */}
                    <pre>
                        <ReactMarkdown>{splitDescription[0]}</ReactMarkdown>
                    </pre>
                </div>
            </div>
        </a>
    );
};

export default Card;
